import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldWindsong: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Windsong?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_windsong.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Windsong?</h1>
          <h2>
            Not sure if pulling Windsong is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>13/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="windsong"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          We don’t usually say yes or no outright, but in this case most likely
          yes. Windsong is widely considered the best single-target burst
          character almost since her release. Among the top-ranked damage
          dealers, Windsong places as the counterpart to Lucy, and together (for
          now) they beat out all the other Carry characters. Luckily, unlike
          Lucy (and Jiu Niangzi), Windsong is not a limited character, and will
          be added to the standard pool in patch 2.1.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Ley Lines. They are beautiful, mysterious and full of grace - at least
          in the eyes of Windsong. She has worked on Ley line theory, including
          independent research, theoretical practice, writing scientific papers,
          participating in academic panels, and even using it as a compass to
          find stray cats and dogs… Unfortunately, most of these efforts had
          little effect. In the eyes of most people, Windsong is just a dreamer,
          a delusional and even a pathetic person who advocates for a
          pseudo-science.
        </p>
        <p>
          Windsong’s ley line studies have taken her to many places. She has
          taken many bad jobs with poor payment, but no matter how bad the bread
          is, it has allowed her to persevere.
        </p>
        <p>
          Coming to Rayashki, she gained a small but eager audience. Here, for
          the first time, someone took her discoveries seriously. For the first
          time, someone encouraged her ideals, and for the first time, someone
          was willing to run with her in the endless unknown. Finally, her
          studies were no longer a personal fantasy, but a tangible reality.
        </p>
        <SectionHeader title="Review" />
        <p>
          Windsong is a 6-star Arcanist of the Star Afflatus. She is an
          incredibly high burst damage Crit oriented character.{' '}
        </p>

        <p>
          Her main Incantation is technically "Rehabilitation", it's what you'll
          attempt to stack in your hand and it's individual casts are what her
          Ultimate, "Long Long Journey", casts all at once. This Incantation's
          damage is increased through the spending of [Eureka], gained though
          casting her Incantations.
        </p>
        <p>
          Her other Incantation, "Practicability" is used to generate
          "Rehabilitation", as it grants Windsong stacks of [Preparation -
          Windsong] that are consumed at the start of the round to generate an
          amount of her cards. Not only that, when "Long Long Journey" is cast,
          all "Practicability" Incantations in hand are transformed into
          "Rehabilitation" ones, further increasing her fuel.
        </p>
        <p>
          As told before, "Long Long Journey", casts all "Rehabilitation"
          Incantations in your hand at once. However, first it applies
          [Confusion] to the target, then it grants [Eureka] to Windsong and
          prevents [Eureka] from being consumed that turn, and for every stack
          of [Eureka] Windsong gains a stack of [Measure] (also granted by her
          Insights whenever an ally crits or inflicts [Confusion]) that
          increases her Crit Rate and DMG.
        </p>
        <p>Gameplay</p>
        <ul>
          <li>Pull "Rehabilitation" Incantations</li>
          <li>Ultimate</li>
          <li>????</li>
          <li>Everything's dead</li>
        </ul>
        <p>
          Oversimplified as that sequence is, that is pretty much what happens.
          Of course, there is nuance to this; I'd argue that Windsong is
          probably one of the hardest characters to play when compared to her
          peers. To make the most of her, you need to think about your card
          management, maximize the cards generated through [Preparation] and
          slowly build your hand to consist of as much Rank 3 "Rehabilitation"
          Incantations as possible to unleash with "Long Long Journey".
        </p>
        <p>
          But that's all you have to pay attention to. Her self-applied
          [Confusion] and the stacks of [Measure] she gains, on top of her other
          Insights, all but guarantee that she at least critically strikes most
          of her attacks, and unlike Spathodea, she doesn't have to actively
          interact with her own kit to maximize her Crit Rate. This is great
          because it opens up a lot of options in terms Supports to pair her
          with: Vila is of course great for her Moxie/Eureka and Crit buffs but
          other characters like 6, Necrologist, 37, Voyager, Twins Sleep, and
          later on with Semmelweis, Mercuria and Kakania, there are very few
          characters she doesn't play nice with.
        </p>
        <p>
          And that's because she is ultimately a very simple gal. She hits Ult,
          things die. The difficulty lies not in the mechanics of her kit but
          the player optimization of it, and with the new Tuning releasing with
          the patch 1.9 anniversary even that part is dumbed down, earning her
          her top spot among the damage dealers.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    One of the best single-target Damage Dealers in the game;
                    insanely high burst potential.
                  </li>
                  <li>
                    Lots of support options due to being self-sufficient in her
                    needs.
                  </li>
                  <li>
                    Generates her own cards which makes setting up for her
                    Ultimate relatively straight-forward.
                  </li>
                  <li>
                    Queen of Crit-oriented teams due to easily accessing max
                    Crit Rate values on her own while also having very high base
                    modifiers.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Takes time to get set-up (5 turn Ultimate rotations are only
                    reliably set with the new Tuning).
                  </li>
                  <li>
                    Not as simple to play compared to other Carry characters.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Windsong works fine at P0.</p>
        <p>
          Windsong’s Portraits are all very viable, but they’re not necessary.
          The only Portrait we don’t recommend aiming for is her second one, as
          she already gets so many stats that P2 doesn’t really matter. When
          looking at Portraits for Windsong, look at the ones that increase the
          damage of “Rehabilitation” since that’s what “Long Long Journey” will
          spam.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          While Windsong has no strict “gimmick” besides Crit, she prefers
          supports that don’t require a lot of set-up due to requiring a lot of
          preparation herself.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="6"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="vila"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="tooth-fairy"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kakania"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Windsong:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="another-story"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="for-rehabilitation"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="outside-the-city"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Another Story (30-turn setups), For Rehabilitation (5-turn setups),
          Blasphemer of Night (if proccable)
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/windsong_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/windsong_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/windsong_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/windsong_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Global Standard: Underpaid School Staff{' '}
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="6"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                Windsong excels at setting up her cards to do incredibly high
                amounts of burst damage through consuming those with her
                Ultimate. In addition, she has very good Crit stats which allows
                her to benefit from Crit supports like Tooth Fairy and Vila, who
                can boost Crit Rate/DMG while also having access to passive
                healing that doesn’t impede on Windsong’s setup.
              </li>
              <li>
                On the other hand, in 3-man settings, Windsong may struggle with
                AP and thus prefers either Supports who can leave Windsong to do
                what she wants or Supports that can help with generating high
                ranked cards.
              </li>
              <li>
                37 and Regulus fall in the former category; technically Regulus
                only has to act every other turn while on the other hand, 37’s
                gimmick is her FUA, so she can be left to fend for herself as
                well. In the latter category we find 6 and An-An Lee. The wise
                old twink is the most common pick, giving access to easy and
                powerful buffs (such as +Incantation Might!) while also
                providing [Incantation Empowerment I] when his Eureka is
                stacked. An-An Lee does something similar (though you need
                Tuning for it) while also providing some defensive coverage (the
                [Daze] debuff and -DMG Dealt) to help Windsong keep safe during
                her set-up.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Global Raid: On A Long Journey<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="6"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Necrologist</strong>
              </li>
              <li>
                Windsong excels at setting up her cards to do incredibly high
                amounts of burst damage through consuming those with her
                Ultimate. In addition, she has very good Crit stats which allows
                her to benefit from Crit supports like Vila, who can boost Crit
                Rate/DMG while also having access to passive healing that
                doesn’t impede on Windsong’s setup.
              </li>
              <li>
                In terms of Support, there are many options. The most popular
                combination is 6 + Necrologist, as 6 provides good buffs from
                his [Duty] Incantation (preferably +Pen Rate or +Incan Might) on
                top of also granting access to targeted [Incantation Empowerment
                I] to help Windsong with upgrading Cards. Necrologist on the
                other hand is there simply to provide that massive +40-50% DMG
                Bonus from her Ultimate, since Windsong doesn’t need that much
                Crit support from allies.
              </li>
              <li>
                Voyager and 37 are options here too. Voyager is a phenomenal
                [Confusion] applier (helping Windsong with her [Measure]
                mechanic) and has access to some pretty amazing crowd control,
                while 37 of course does very funny things off of a well prepared
                Windsong Ultimate (all Windsong’s “Rehabilitation” casts under
                [Mapping] count as FUA).
              </li>
              <li>
                Yenisei is also to be considered for this team if you feel like
                it is too flimsy and to have another self-upgrader on the team
                for Tuning.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            CN Standard: Underpaid School Staff{' '}
            <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="6"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania</strong>
              </li>
              <li>
                The holy grail of single-target teams. Windsong excels at
                setting up her cards to do incredibly high amounts of burst
                damage through consuming those with her Ultimate. In addition,
                she has very good Crit stats which allows her to benefit from
                Crit supports like Vila, who can boost Crit Rate/DMG while also
                having access to passive healing that doesn’t impede on
                Windsong’s setup.
              </li>
              <li>
                The optimal set-up here would be to take 6 (or Mercuria if at
                least P1) + Kakania and Vila, though any of the options are
                viable. Why? Because 6 provides very consistent buffing and
                [Incantation Empowerment I] which can speed up the set-up
                process (Mercuria has a bunch of amazing offensive buffs but
                she’s extremely hard to play as you’ll have to track 2 rounds in
                advance to upkeep her [Cosmic Energy] or you’ll lose all your
                relevant buffs). Kakania is used because she has a similar
                ramping playstyle to Windsong (which ends up resulting in 2
                massive bursts of damage, one from Windsong and one from
                Kakania) while also providing an amazing ATK buff with [Solace].
              </li>
              <li>
                The two other options here are 37, who is still somewhat
                relevant but definitely no longer considered for the team if not
                at P2+ due to her lacking kit. Barbara is an interesting play
                again if you’re using Semmelweis (check CN Standard Team) or
                simply as a 5-star alternative to someone like 6, as she can
                transform her own cards into allied cards with [Dream Visitor].
                Is she good? In a Raid setting probably not, but it is an
                option.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            CN Raid: Hydrogen Bomb vs Baby Raid{' '}
            <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                Windsong excels at setting up her cards to do incredibly high
                amounts of burst damage through consuming those with her
                Ultimate. In addition, she has very good Crit stats which allows
                her to benefit from Crit supports like Vila, who can boost Crit
                Rate/DMG while also having access to passive healing that
                doesn’t impede on Windsong’s setup.
              </li>
              <li>
                In 3-man settings, Windsong prefers Supports that don’t really
                need to act to maximize their full potential. Semmelweis and
                Mercuria are good examples of this, either providing increasing
                DMG Bonus and Crit buffs through passively applied effects or a
                single incantation while Mercuria can AFK for the most part and
                still provide most of her buffs. Note that Semmelweis is a very
                risky pick due to the HP Drain she applies to allies. 6 is here
                for extra reliable buffing and [Incantation Empowerment I].
              </li>
              <li>
                Vila is of course the obvious pick, but Kakania and Barbara are
                interesting options. Kakania provides a great ATK buff with
                [Solace], which Windsong benefits from greatly as she gets a lot
                of Crit stats on her own, while Barabara has the unique feature
                of transforming her own cards into those of allies with [Dream
                Visitor]. In addition, her hyper consistent shielding and
                passive healing allow her to make Semmelweis teams a bit more
                manageable.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldWindsong;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Windsong? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Windsong is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
